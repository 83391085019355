import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';

// 使用setup模式定义
export const useToken = defineStore('token', () => {
    // token信息
    let accessToken = ref<string>("");
    let historyCityList = ref<Array<string>>([]);

    function setHistoryCityList(data: Array<string>)
    {
        historyCityList.value = data;
    }
    
    function setAccessToken(data: string)
    {
        accessToken.value = data;
    }

    // 当前请求数量
    let loadingCount = ref<number>(0);

    function setLoadingCount(data: number)
    {
        loadingCount.value = data;
    }

    // 刷新token
    let refreshToken = ref<string>("");

    // 修改刷新toke
    function setRefreshToken(data: string) {
        refreshToken.value = data;
    }   

    return { accessToken, setAccessToken, setHistoryCityList,historyCityList,refreshToken, setRefreshToken, loadingCount, setLoadingCount };

},{
    persist: {
        enabled: true,
        strategies: [
          {
            storage: localStorage,
            paths: ["accessToken", "refreshToken","historyCityList"]
          }
        ]
    }
});