import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e852d0ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-back-content van-hairline--bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "page-back",
    style: _normalizeStyle(`padding-top:${_ctx.systemHeight.statusBarHeight}px;`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showBack)
        ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 0,
            class: "page-back-icon",
            name: "arrow-left",
            onClick: _ctx.goBack
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.title)
        ? _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ], true)
        : _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
            _createTextVNode(_toDisplayString(_ctx.routeName), 1)
          ], true)
    ])
  ], 4))
}