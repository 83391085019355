import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';

// 使用setup模式定义
export const useSystemHeight = defineStore('systemHeight', () => {
    // token信息
    let state = reactive({
        navigateBarHeight :0,//状态栏⾼度 单位px
        statusBarHeight :0,//底部标签栏⾼度 单位px
        tabBarHeight:0,//安卓物理导航栏⾼度,单位px
    })

    function setSystemHeight(data: any) {
        state.navigateBarHeight  = data.navigateBarHeight;//状态栏⾼度 单位px
        state.statusBarHeight  = data.statusBarHeight;//状态栏⾼度 单位px
        state.tabBarHeight  = data.tabBarHeight;//状态栏⾼度 单位px
    };

    return { state, setSystemHeight};

});