import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 我的任务相关服务
 * @class
 * @version 1.0.0
 */
export default class MemberService extends ServiceBase
{

    /**
     * 实例
     * @private
     * @return MemberService
     */
     private static _instance: MemberService;

    /**
     * 实例
     * @static
     * @property
     * @returns MemberService
     */
    public static get instance(): MemberService
    {
        if(!this._instance)
        {
            this._instance = new MemberService();
        }
        
        return this._instance;
    }

    /**
     * 获取我的客户
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getMemberList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getMemberList({ 
            data: data 
        });
        
        return response;
    }


}