
import { computed, defineComponent, watch, getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import { Toast } from "vant";


export default defineComponent({
    props:
    {
        /**
         * 数据源
         * @protected
         * @returns Function
         */
        data:{
            type: Function,
            required: true
        },
        /**
         * 无数据图片
         * @protected
         * @returns String
         */
        imgSrc:{
            type: String,
            default: "/images/notData.png"
        },
         /**
         * 无数据标题
         * @protected
         * @returns String
         */
        noDataTitle:{
            type: String,
            default: "暂无数据"
        }
    },
    setup(props, context){
        let state = reactive({
            refreshing: false,  // 是否刷新
            loading: false,     // 加载效果
            finished: false,    // 是否到底
            pageIndex: 1,       // 当前页
            pageSize: 5,       // 每页条数
            totalPage: 1,        // 总页数
            viewHeight: 0,      // 内容显示区域高度
            dataList: [],       // 数据集合
            handling: false     // 防止重复加载
        })
        
        /**
         * 是否显示完成插槽
         * @param Boolean bool
         */
        const isShowFinishedSlot = computed({
            get() {
                return !!context.slots.finished;
            },
            set(value)
            {
                
            }
        })

        /**
         * 表格重新加载方法
         * 如果参数为 true, 则强制刷新到第一页
         * @param Boolean bool
         */
        const onRefresh = (): void =>
        {
            state.pageIndex = 1;
            state.totalPage = 1;
            state.dataList = [];
            state.finished = false;
            loadData();
        }

        /**
         * 加载数据方法
         * @param {Object} pagination 分页选项器
         */
         const loadData = async (pagination?:any) =>
        {
            if (state.pageIndex > state.totalPage)
            {
                state.finished = true;
            }
            if (state.finished || state.loading)
            {
                return;
            }
            state.loading = true;
            const parameter = Object.assign({
                pageIndex: state.pageIndex,
                pageSize: state.pageSize
            })
            try
            {
                const result = await (props as any).data(parameter);
                if (result)
                {
                    state.pageIndex ++;
                    state.totalPage = result.totalPages;
                    state.dataList = state.dataList.concat(result.items || []); 
                }
                else
                {
                    state.totalPage = 0;
                    state.finished = false;
                }
            }
            catch (error)
            {
                Toast(error);
            }
            finally
            {
                state.loading = false;
                state.refreshing = false;
            }
        }
       
        return{
            ...toRefs(state),
            loadData,
            onRefresh,
            isShowFinishedSlot
        }
    }
})
