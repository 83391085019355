import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { number } from "echarts";

/**
 * 消息相关服务
 * @class
 * @version 1.0.0
 */
export default class NotityService extends ServiceBase
{

    /**
     * 实例
     * @private
     * @return NotityService
     */
     private static _instance: NotityService;

    /**
     * 实例
     * @static
     * @property
     * @returns NotityService
     */
    public static get instance(): NotityService
    {
        if(!this._instance)
        {
            this._instance = new NotityService();
        }
        
        return this._instance;
    }

    /**
     * 获取消息数量数据
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getCountNotify(): Promise<IHttpResponse>
    {
        let response = await this.apis.getCountNotify({ });
        return response;
    }

    /**
     * 获取消息列表数据
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async notifyPageList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.notifyPageList({ 
            data: data 
        });
        return response;
    }

    /**
     * 处理消息
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async notifyHandle(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.notifyHandle({ 
            params: {
                id
            } 
        });
        return response;
    }

    /**
     * 校验消息是否可进行查看详情
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async checkNotify(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.checkNotify({ 
            params: {
                id
            } 
        });
        return response;
    }


}