
import { computed, defineComponent, watch, getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import { Toast, Calendar } from "vant";
import { PhysicalService } from "src/services";
import dayjs from "dayjs";

export default defineComponent({
    props:
    {
        /**
         * 任务id
         * @private
         * @returns String || Number
         */
        taskId:{
            type: Number
        },
        /**
         * 机构id
         * @private
         * @returns String || Number
         */
        hospitalId:{
            type: Number
        },
        /**
         * 失效日期
         * @private
         * @returns String || Number
         */
        invalidateTime:{
            required: true,
        },
        /**
         * 姓名
         * @private
         * @returns String || Number
         */
        userName:{
            required: true,
            type:String
        },
        /**
         * 是否显示
         * @private
         * @returns Boolean
         */
        value:{
            type: Boolean,
            default: false
        }
    },
    setup(props, context){
        let state = reactive({
            schedulingData:[], //      * 排期数据
            defaultDate: dayjs("1970/01/01").toDate(),    //      * 默认日期
            minDate: "",    //      * 最小日期
            maxDate: "",    //      * 最大日期
            currentDate: "",
            showModal: false,
            loading: false
        })
        
        /**
         * 是否显示日期弹框 
         * @private
         * @returns boolean
         */
        const showDate = computed({
            get() {
                return props.value;
            },
            set(value)
            {
                context.emit("update:value", value);
            }
        })

        /**
         * 监听弹框变化
         * @private
         * @returns void
         */
        watch(showDate, (value: boolean) => {
            if(value)
            {
                state.showModal = false
                getMedicalOrgDateInfo();
            }
        });

        /**
         * 选中当前排期
         * @private
         * @returns void
         */
        const onSelectDate = (day: any): void =>
        {
            state.showModal = false;
            let date: any = props.invalidateTime;
            if(dayjs(day).isAfter(date, "day"))
            {
                state.showModal = true;
            }
            state.currentDate = day;
        }

        /**
         * 自定义排期格式
         * @private
         * @returns void
         */
        const formatter = computed(() => {
            return (day)=>
            {
                let date = dayjs(day.date).format("YYYY/MM/DD");
                let selectDate = state.schedulingData.filter(item => dayjs(item.startTime).isSame(dayjs(date)));
                
                if(selectDate && selectDate.length > 0 && selectDate[0].personCount > 0)
                {
                    // day.bottomInfo = `余${selectDate[0].personCount}`;
                    day.bottomInfo = '可约';
                    let date: any = props.invalidateTime
                    if(dayjs(day.date).isAfter(date, "day"))
                    {
                        day.className = 'calendar-invalidate';
                    }
                }
                else if(selectDate && selectDate.length > 0 && selectDate[0] && selectDate[0].personCount === 0)
                {
                    // day.bottomInfo = `余0`;
                    day.bottomInfo = '不可约';
                    day.className = "no-day-num"
                    day.type = "disabled";
                }
                else
                {
                    day.type = "disabled";
                    
                }

                return day;
            }
        });

        /**
         * 自定义排期格式
         * @private
         * @returns void
         */
        // const formatter = (day: any): void =>
        // {
        //     let date = dayjs(day.date).format("YYYY/MM/DD");

        //     let selectDate =  state.schedulingData.filter(item => dayjs(item.startTime).isSame(dayjs(date)));

        //     if(selectDate && selectDate.length > 0 && selectDate[0].personCount > 0)
        //     {
        //         day.topInfo = `剩余${selectDate[0].personCount}`;
        //     }
        //     else if(selectDate && selectDate.length > 0 && selectDate[0] && selectDate[0].personCount === 0)
        //     {
        //         day.topInfo = "已约满";
        //         day.type = "disabled";
        //     }
        //     else
        //     {
        //         day.type = "disabled";
                
        //     }
        //     return day;
        // }

        /**
         * 日期确认选择
         * @private
         * @returns string
         */
        const onDateConfirm = (): void =>
        {
            let date = state.currentDate;
            
            // 判断选择的数据是否在排期之内
            let pd = (state.schedulingData.filter(res => dayjs(res.startTime).format("YY/MM/DD") == dayjs(date).format("YY/MM/DD"))).length == 1;
            if (!pd)
            {
                Toast("请选择有效的排期日期！");
                return;
            }
            if(dayjs("1970/01/01").isSame(dayjs(date)))
            {
                context.emit("onDateConfirm", null);
            }
            else
            {
                context.emit("onDateConfirm", dayjs(date).format("YYYY/MM/DD"));
            }
            showDate.value = false;
        }

        /**
         * 获取某个机构排期
         * @private
         * @returns void
         */
        const getMedicalOrgDateInfo = async (): Promise<void> =>
        {
            try
            {
                state.schedulingData = [];
                state.loading = true;
                let {content: result} =  await PhysicalService.instance.getSchedules({
                    taskId: props.taskId,
                    hospitalId: props.hospitalId,
                });
                
                state.schedulingData = result.data || [];
                state.loading = false;
            }
            catch(err)
            {
                state.loading = false;
                Toast(err);
            }
        }

        return{
            ...toRefs(state),
            showDate,
            onSelectDate,
            onDateConfirm,
            getMedicalOrgDateInfo,
            formatter
        }
    }
})
