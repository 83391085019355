export default function loadBMap (ak) {
    return new Promise(function (resolve, reject) {
      if (typeof (window as any).BMapGL !== 'undefined') {
        resolve((window as any).BMapGL)
        return true
      }
  
      // 百度地图异步加载回调处理，不使用加载的script不会执行
      (window as any).onBMapCallback = function () {
        console.log('百度地图脚本初始化成功...')
        resolve((window as any).BMapGL)
      }
  
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `//api.map.baidu.com/api?type=webgl&v=3.0&ak=${ak}&s=1&callback=onBMapCallback`
      // 启用 https 配置，通过 s=1 参数实现
      // 启用 callback 参数，异步加载必须使用此参数才可以生效
      script.onerror = reject
      document.documentElement.appendChild(script)
    })
  }