import API from "./urls";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import IHttpRequest from "src/http/http-request";

/**
 * 发送ajax请求
 * @param {any} options 请求参数
 * @param {string} url 请求地址
 * @param {string} method 请求类型
 * @returns {any}
 */
const send = (url: string, options?: IHttpRequest, method: any = "post"): IHttpResponse =>
{
    // 如果未传入url以及参数抛出异常
    if (!url)
    {
        throw new Error("url is null.");
    }

    // 返回promise实例由用户处理
    return HttpClient.instance[method](
    {
        url,
        ...options
    });
};

/**
 * 接口类
 * @version 1.0.0
 */
export default class Apis
{
    // 登录授权相关服务
    public login = (option?: IHttpRequest) => send(API.login, option);
    public authCodeLogin = (option?: IHttpRequest) => send(API.authCodeLogin, option);
    public getQuestionnaireList = (option?: IHttpRequest) => send(API.getQuestionnaireList, option);
    public getPhysicalList = (option?: IHttpRequest) => send(API.getPhysicalList, option);
    public getMemberList = (option?: IHttpRequest) => send(API.getMemberList, option);
    public getphysicalDetail = (option?: IHttpRequest) => send(API.getphysicalDetail, option, "get");
    public cancelReserve = (option?: IHttpRequest) => send(API.cancelReserve, option, "put");
    public getSchedules = (option?: IHttpRequest) => send(API.getSchedules, option);
    public changeReserve = (option?: IHttpRequest) => send(API.changeReserve, option);
    public getPackageHospitals = (option?: IHttpRequest) => send(API.getPackageHospitals, option);
    public reservePackage = (option?: IHttpRequest) => send(API.reservePackage, option);
    // 城市
    public locationCity = (option?: IHttpRequest) => send(API.locationCity, option, "get");

    // 消息
    public getCountNotify = (option?: IHttpRequest) => send(API.getCountNotify, option, "get");
    public notifyPageList = (option?: IHttpRequest) => send(API.notifyPageList, option);
    public notifyHandle = (option?: IHttpRequest) => send(API.notifyHandle, option, "get");
    public checkNotify = (option?: IHttpRequest) => send(API.checkNotify, option, "get");
    
}
