import { createApp } from 'vue'
import App from './App.vue';
import router from './router'
import { createPinia } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist';
import Vant from 'vant';
import 'vant/lib/index.css';
// import "amfe-flexible";
// import VConsole from "vconsole";

// let cos = new VConsole();
const store = createPinia()
store.use(piniaPluginPersist)

const app = createApp(App);
app.use(store);
app.use(Vant).use(router).mount('#app');
