
class AlipayJS  {

    protected static alipayJSInstance:AlipayJS = null;

    static get instance(): AlipayJS
    {
        if(!this.alipayJSInstance)
        {
            this.alipayJSInstance = new AlipayJS();
        }

        return this.alipayJSInstance;
    }


    JSBridgeReady(callback){
        if (window.AlipayJSBridge)
        {
            callback && callback();
        }
        else
        {
            document.addEventListener("AlipayJSBridgeReady", callback,false);
            
        } 
    }

    callAlipayJS(eventName,params,callback)
    {
        AlipayJS.instance.JSBridgeReady(()=>
        {
            window.AlipayJSBridge.call(eventName,params,callback)
        })
    }

}

export default AlipayJS;