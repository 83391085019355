
import router from '@/router';
import { Toast } from 'vant';
import { defineComponent, computed, reactive, ref, watch, toRefs } from 'vue';
import { NotityService } from 'src/services';

export default defineComponent({
    props:{
        active:
        {
            type: Number,
            default: 0
        }
    },
    setup(props){
        const state = reactive({ active: 0, hasMessage: false });
        
        /**
         * 获取消息数量数据
         */
         const getCountNotify = async ()=>
        {
            try {
                let { content: res } = await NotityService.instance.getCountNotify();
                if (res && res.data)
                {
                    state.hasMessage = res.data.hasMessage;
                }
            } catch (err) {
                Toast(err);
            }
        }

        watch(props, (nweProps, oldProps) => {
            state.active = (nweProps.active as number);
            getCountNotify();
        },{immediate: true});

        const menuList =
        [
            {
                routeName: "home"
            },
            {
                routeName: "customer-notifyList"
            },
            {
                routeName: "notify"
            },
            {
                routeName: "my"
            }
        ]

        const onChange = (index)=>
        {
            getCountNotify();
            if(index!== state.active)
            {
                let routeName = menuList[index].routeName;
                router.push({name: routeName})
            }
        }

        return{
            ...toRefs(state),
            onChange,
            getCountNotify
        }
    }
})

