
import globalConfig from "../config/index";
const apiUrl = globalConfig.server;

export default
{
    // 登录授权相关服务
    login:                      `${apiUrl}/aia/proxyAuth/login`,                // 授权码登入
    authCodeLogin:                      `${apiUrl}/aia/proxyAuth/authCodeLogin`,                // 授权码登入
    getQuestionnaireList:       `${apiUrl}/aia/proxy/getQuestionnaireTaskPage`,         // 获取问卷任务
    getPhysicalList:            `${apiUrl}/aia/proxy/getPhysicalTaskPage`,              // 获取体检任务
    getMemberList:              `${apiUrl}/aia/proxy/getMemberPage`,                    // 获取我的客户
    getphysicalDetail:           `${apiUrl}/aia/physicalTask/proxy/get`,                // 代理人获取体检任务详细
    cancelReserve:              `${apiUrl}/aia/physicalTask/proxy/cancelReserve`,       // 代理人取消预约
    getSchedules:              `${apiUrl}/aia/physicalHospital/getSchedule`,            // 获取排期
    changeReserve:              `${apiUrl}/aia/physicalTask/proxy/changeReserve`,       // 代理人改约体检
    getPackageHospitals:        `${apiUrl}/aia/physicalHospital/getPackageHospitals`,   // 获取套餐支持的体检机构
    reservePackage:             `${apiUrl}/aia/physicalTask/proxy/toReserve`,           // 预约体检
    locationCity:               `${apiUrl}/aia/region/cities`,                               // 获取切换定位城市

    // 消息
    getCountNotify:             `${apiUrl}/aia/proxy/notify/getCount`,                  // 获取消息数量数据
    notifyPageList:             `${apiUrl}/aia/proxy/notify/query`,                     // 获取消息列表数据
    notifyHandle:               `${apiUrl}/aia/proxy/notify/handle`,                    // 处理消息
    checkNotify:                `${apiUrl}/aia/proxy/notify/check`,                     // 校验消息是否可进行查看详情
    
    
};
