
import { computed, defineComponent, watch, getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import { Toast } from "vant";
import { GaodeService, LocationcityService } from "src/services";
import { useToken } from 'src/store';


export default defineComponent({
    props:
    {
        // 是否显示
        value:{
            type: Boolean,
            default: false
        }
    },
    setup(props, context){
        const { historyCityList, setHistoryCityList } = useToken();
        let state = reactive({
            hotCity: ["北京市", "上海市", "广州市", "深圳市", "南京市", "杭州市", "成都市", "武汉市"], // 热门城市
            indexList: ["定位", "历史", "热门",], // 索引字符列表
            searchKey: "",          // 搜索关键字
            currentLocaltion: "",   // 当前位置
            cityList: [],           // 城市列表
            searchResult: [],       // 搜索结果
            showSeachModel: false   // 搜索结果的弹框显示或隐藏
        })


        /**
         * 是否显示日期弹框
         * @private
         * @returns boolean
         */
        const showModel = computed({
            get ()
            {
                return props.value;
            },
            set(value)
            {
                context.emit("update:value", value);
            }
        })
        
        /**
         * 触发搜索城市事件
         * @private
         * @returns string
         */
        const onSearchCity = (): void =>
        {
            onSearch();
        }
 
        // /**
        //  * 获取当前位置信息
        //  * @private
        //  * @returns void
        //  */
        // const getGeolocation = async (): Promise<void> =>
        // {
        //     let data = await GaodeService.instance.getGeolocation();
            
        //     state.currentLocaltion = data.city;
        // }
 
        /**
         * 取消搜索城市事件
         * @private
         * @returns void
         */
        const onClearCityRseult = (): void =>
        {
            state.searchResult = state.cityList;
        }
    
        /**
         * 选中城市
         * @private
         * @returns void
         */
        const onSelectCity = (city: string): void =>
        {
            let newHstoryCityList: any = historyCityList || [];
            let selectIndex = newHstoryCityList.indexOf(city);
            
            if(newHstoryCityList && newHstoryCityList.length < 3 && selectIndex === -1)
            {
                newHstoryCityList.push(city);
            }
            else if(selectIndex > -1)
            {
                
                newHstoryCityList.splice(selectIndex, 1);
                newHstoryCityList.push(city);
            }
            else
            {
                newHstoryCityList.shift();
                newHstoryCityList.push(city);
            }
            showModel.value = false;

            setHistoryCityList(historyCityList)

            // store.dispatch("setHistoryCityList", historyCityList.value);

            context.emit("on-select-city", city);
        }


        /**
         * 清空历史
         * @private
         * @returns void
         */
        const onClearHistory = (city: string): void =>
        {
            // store.dispatch("setHistoryCityList", []);
        }

        /**
         * 搜索
         * @private
         * @returns void
         */
        const onSearch = (): void =>
        {
            state.searchResult = state.cityList.map((item: any) =>
            {
                const viewCityItems = item.ViewCityItems.filter(data => (data.CityName.indexOf(state.searchKey) > -1));
                if(viewCityItems && viewCityItems.length > 0)
                {
                    return{
                        ...item,
                        ViewCityItems: viewCityItems
                    };
                }
            });
        }


        /**
         * 获取定位城市
         * @private
         * @returns Promise<void>
         */
        const getLoctaionCity = async (): Promise<void> =>
        {
            try
            {
                let {content: result} =  await LocationcityService.instance.locationCity();
                if(result.data)
                {
                    state.cityList = result.data;
                    state.searchResult = result.data;
                    result.data.forEach(item=>
                    {
                        state.indexList.push(item.cityInitials)
                    })
                    
                }
            }
            catch(err)
            {
                Toast(err);
            }
        }
        // 钩子
        onMounted(() => {
            getLoctaionCity();
            // getGeolocation();
            let location  = JSON.parse(localStorage.getItem("location"))
            state.currentLocaltion = location.city;
        })

        return{
            ...toRefs(state),
            showModel,
            onSelectCity,
            historyCityList
        }
    }
})

