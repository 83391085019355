import { useRoute } from 'vue-router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/views/login.vue';
import Questionnaire from '@/views/questionnaire.vue';
import Physical from '@/views/physical.vue';
import My from '@/views/my.vue';
import Detail from '@/views/detail.vue';
import OrgList from '@/views/org-list.vue';
import Sucess from '@/views/sucess.vue';
import Notify from '@/views/notify.vue';

const routes: Array<RouteRecordRaw> =
[
    {
        path: '/',
        redirect:"/login"
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta:{
            pageName: "账号登陆"
        }
    },
    {
        path: '/questionnaire',
        name: 'questionnaire',
        component: Questionnaire,
        meta:{
            pageName: "问卷任务"
        }
    },
    {
        path: '/physical',
        name: 'physical',
        component: Physical,
        meta:{
            pageName: "体检任务"
        }
    },
    {
        path: '/my',
        name: 'my',
        component: My,
        meta:{
            pageName: "我的客户"
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: Detail,
        meta:{
            pageName: "预约详情"
        }
    },
    {
        path: '/org',
        name: 'org',
        component: OrgList,
        meta:{
            pageName: "机构选择"
        }
    },
    {
        path: '/sucess',
        name: 'sucess',
        component: Sucess
    },
    {
        path: '/notify',
        name: 'notify',
        component: Notify,
        meta:{
            pageName: "消息通知"
        }
    },
    

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
})

export default router
