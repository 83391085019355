
import { computed, defineComponent, watch, getCurrentInstance, onMounted, reactive, toRefs } from 'vue';
import { useRouter, useRoute } from "vue-router";
import {useToken, useSystemHeight } from 'src/store';
import AlipayJS from "src/utils/AlipayJSBridge";

export default defineComponent({
    props:
    {
        // 页面标题
        showBack:{
            type: Boolean,
            default: true
        },
        // 页面标题
        title:{
            type: String,
        },
        // 页面跳转路由名称
        pathName:{
            type: String
        },
        // 关闭在app内到应用
        closeWindow:{
            type: Boolean,
            default: false
        }
    },
    emits:["getHeadHeight"],
    setup(props,ctx){
        const router = useRouter();
        const route = useRoute();
        const systemHeight = computed(()=>
        {
            const { state } = useSystemHeight();

            return state;
        })

        let state = reactive({
            routeName:""
        })

        onMounted(()=>
        {
            state.routeName = route && route.meta && (route.meta.pageName as string) || "核保平台";
            ctx.emit("getHeadHeight",(+systemHeight.value.statusBarHeight) + 40)
        })

        // 返回
        const goBack=()=>
        {
            if(props.closeWindow)
            {
                AlipayJS.instance.callAlipayJS("closeWindow",{
               
                },(res)=>
                {
                    
                });
               
            }
            else if(props.pathName)
            {
                let pathName = (props.pathName as string);
                router.push({name: pathName});
            }
            else
            {
                router.go(-1);
            }
        }

        return{
            ...toRefs(state),
            goBack,
            systemHeight
        }
    }
})

