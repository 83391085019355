import JSEncrypt from "jsencrypt";

const publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtS35B/tHcTY8uyi6/2vb
PI1ho/bh48XSp3M9rM3/tVh0uxhZPKtIRsCqezshNk2d5Z2vNM44tqytjVsWQsSh
QEElKot3htDEgs4GYWBqPdgiHVi8iyPBiyrssD9z4NOem3RRIVkE3EzCFFm1UO/3
/vpLjPFfDkikRI/eE0pkbgzKvS04AcBRHc8pbfRgb79n3O5TS3W9o1NfgNBUJQix
g+WHz7ojPrKTuVy9hL9FL3Xp0ap4Y3RdJQersJwprMtrNgCL7pQ1uIPFliGXWH37
2iMXWN1y9tn9x0okAdrhJCgaoQBeBUkKngRkWHNwhsqtvRGiwpVW1tHXSUTlWcq1
/QIDAQAB`;

export default class JSEncryptUtils
{
    /**
     * 加密
     * @param word 
     * @returns 
     */
    public static decrypt(word: string): string {
        
        let jse = new JSEncrypt();
        jse.setPublicKey(publicKey);
        let str = (jse.encrypt(word) as string);
        return str;
    }
   
}