import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 问卷任务相关服务
 * @class
 * @version 1.0.0
 */
export default class QuestionnaireService extends ServiceBase
{

    /**
     * 实例
     * @private
     * @return QuestionnaireService
     */
     private static _instance: QuestionnaireService;

    /**
     * 实例
     * @static
     * @property
     * @returns QuestionnaireService
     */
    public static get instance(): QuestionnaireService
    {
        if(!this._instance)
        {
            this._instance = new QuestionnaireService();
        }
        
        return this._instance;
    }

    /**
     * 登录
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getQuestionnaireList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getQuestionnaireList({ 
            data: data 
        });
        
        return response;
    }


}